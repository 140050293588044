
export const detectDeviceType = () => {
    const userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) return 'android';
    if (/iPhone/i.test(userAgent)) return 'iphone';
    if (/iPad/i.test(userAgent)) return 'ipad';
    if (/Windows Phone/i.test(userAgent)) return 'windows phone';
    if (/Linux/i.test(userAgent)) return 'linux';
    if (/Mac/i.test(userAgent)) return 'mac';
    if (/Windows/i.test(userAgent)) return 'windows';
    return 'otro';
};
