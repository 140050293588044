import { toast } from 'react-toastify';
import { insertTableBd, getTableBd, deleteBd } from './functions';

export const crearPlantillaCompletaDefault = async (tipo, rig, user, uid, setRig, functionN) => {

    const tldroUser = rig + ':' + uid
    const tldroUname = rig + ':' + user

    if (tipo === 'graph') {
        await deleteBd(tldroUname, 'deletedatamatrizgraphusuario')
        await deleteBd(tldroUser, 'deletedatamatrizgraphizqusuario')
    } else {
        await deleteBd(tldroUser, 'deletedatamatrizusuario')
    }

    const datosPlantillas = await getTableBd(tipo === 'graph' ? 'getdataplantillagraphdefault' : 'getdataplantillalddefault')
    const datosPlantillasIzquierda = (tipo === 'graph' ? await getTableBd('getdataplantillagraphIzqdefault') : [])

    try {
        for (const dp of datosPlantillas) {
            const fecha = new Date();
            let dataSend = {};
            if (tipo === 'graph') {
                dataSend = {
                    nb_tldro_idn: parseInt(rig),
                    nb_var_idn: parseInt(dp.nb_var_idn),
                    vr_adt_usureg: user,
                    dt_adt_fchreg: fecha.toISOString(),
                    nb_tldvrb_ord: dp.nb_grafica_orden,
                    nb_tldvrb_min: dp.nb_grafica_minimo,
                    nb_tldvrb_max: dp.nb_grafica_maximo
                };
            } else {
                dataSend = {
                    nb_tldro_idn: parseInt(rig, 10),
                    nb_var_idn: parseInt(dp.nb_var_idn, 10),
                    nb_usurio_idn: uid,
                    nb_matr_fila: 0,
                    nb_matr_colum: parseInt(dp.nb_datos_orden, 10),
                    vr_adt_usureg: user,
                    dt_adt_fchreg: fecha.toISOString(),
                };
            }
            await insertTableBd(tipo === 'graph' ? 'createvarmatrizgraph' : 'createvarmatriz', dataSend);
        }

        if (tipo === 'graph') await crearPlantillaCompletaIzqDefault(datosPlantillasIzquierda, uid, user, rig, setRig);
        else await functionN(rig);
        toast.success('Plantilla por defecto asignada correctamente, espere que los datos se actualicen');

    } catch (error) {
        toast.warning('bd don\'t work');
    }
}

const crearPlantillaCompletaIzqDefault = async (datosPlantillasIzquierda, uid, user, rig, setRig) => {
    try {
        datosPlantillasIzquierda.map(async (dp) => {
            const fecha = new Date()
            const dataSendIzq = {
                nb_tldro_idn: parseInt(rig),
                nb_var_idn: parseInt(dp.nb_var_idn),
                nb_usurio_idn: parseInt(uid),
                nb_datos_grafica_orden: parseInt(dp.nb_datos_orden),
                vr_adt_usureg: user,
                dt_adt_fchreg: fecha.toISOString()
            }
            await insertTableBd('createvarmatrizizq', dataSendIzq);
            setRig(rig)
        });
    } catch (error) {
        //toast.warning('bd don\'t work');
    }
}
