import React, { useEffect, useState } from 'react'
import { LoaderES } from './LoaderES';
import { Filtrar } from './Filtrar';
import { deleteBdNoId, getTableByIdBd, ordenar } from '../config/functions';
//import { toast } from 'react-toastify';
import { Collapse } from 'react-bootstrap';
import { Pagination } from './Pagination';
import moment from 'moment';

const MyRigs = ({ broken, collapsed, setRig, setComponente, mobile, uid, colors }) => {

    const [taladrosUsuario, setTaladrosUsuario] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [documentosFiltrados, setDocumentosFiltrados] = useState([]);

    const [registrosPorPagina, setRegistrosPorPagina] = useState(20);
    const [paginaActual, setPaginaActual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(0)
    const [registrosPaginaActual, setRegistrosPaginaActual] = useState([])
    const [asignaciones, setAsignaciones] = useState(false)
    const [open, setOpen] = useState(null);

    useEffect(() => {
        getRigs()
        const intervalId = setInterval(() => { getRigs() }, 10000);
        return () => clearInterval(intervalId);
    }, []);

    const getRigs = async () => {
        setIsLoading(true);
        try {
            const data = await getTableByIdBd(uid, 'getrigsusers');
            if (data && data.length >= 1) {
                setTaladrosUsuario(data);
                setDocumentosFiltrados(data)
            } else {
                setTaladrosUsuario([]);
                setDocumentosFiltrados([]);
            }
        } catch (error) {
            //console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    //---------------------------------------------

    useEffect(() => {
        setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
    }, [documentosFiltrados, registrosPorPagina])

    useEffect(() => {
        const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
        const fin = inicio + parseInt(registrosPorPagina)
        setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
    }, [paginaActual, totalPaginas])

    //---------------------------------------------

    const eliminarAsignacionesDuplicadas = async () => {
        try {
            await deleteBdNoId('asignacionesduplicadas', true)
            getRigs()
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (asignaciones) eliminarAsignacionesDuplicadas()
    }, [asignaciones])

    //---------------------------------------------

    const thOrd = (name, col) => {
        return (
            <th scope="col">{name}&nbsp;&nbsp;
                <i className="fa-solid fa-arrow-down-a-z"
                    onClick={() => ordenar(col)}
                    style={{ cursor: "pointer" }}></i>
            </th>
        )
    }

    return (
        <>
            {(taladrosUsuario.length === 0 /* || isLoading */) && <LoaderES />}

            <>
                <div className={`row ${mobile ? 'mb-3' : ''}`}>
                    <div className="col-3 col-md-1">
                        <div className="btn-group">
                            <button
                                className="fa-solid fa-arrows-rotate btn"
                                style={{ color: colors?.colorFont || '#000000', borderColor: colors?.colorFont || '#000000' }}
                                title='Refrescar datos'
                                onClick={() => getRigs()} />
                            {/* {asignaciones && (
                                <button
                                    className="fa-solid fa-triangle-exclamation btn btn-warning"
                                    title='Eliminar Asignaciones duplicadas'
                                    onClick={() => eliminarAsignacionesDuplicadas()} />
                            )} */}
                        </div>
                    </div>
                    <div className="col-9 col-md-11">
                        <Filtrar
                            documentos={taladrosUsuario}
                            setDocumentosFiltrados={setDocumentosFiltrados}
                            setRegistrosFiltrados={setRegistrosPaginaActual}
                            registrosPorPagina={registrosPorPagina}
                            colors={colors} />
                    </div>
                </div>

                {mobile
                    ? registrosPaginaActual && registrosPaginaActual.map((r, index) => (
                        <div key={r.nb_tldro_idn}>
                            <table className="table table-sm table-hover table-striped mb-0"
                                style={{ color: colors?.colorFont }}
                                onClick={() => setRig(parseInt(r.nb_tldro_idn))}>
                                <thead style={{ fontSize: '14px', color: colors?.colorFont }}>
                                    <tr >
                                        <th style={{ width: '50px', resize: 'none' }}>
                                            <button className="btn  fa-solid fa-chevron-down btn-sm"
                                                style={{ color: colors?.colorFont }}
                                                onClick={() => setOpen(open === index ? null : index)}
                                                aria-controls={"collapse-rig-" + index}
                                                aria-expanded={open === index}
                                            />
                                        </th>
                                        <th style={{ width: '150px', resize: 'none' }}>
                                            <div>{`${r.vr_tldro_nom.split('-')[1]}  `} </div>
                                            <div style={{ fontSize: '8px' }}>
                                                {/* espacios en blanco */}
                                                {` (${moment(r.dt_adt_last_fchreg).format('DD-MMMM-YY, h:mm:ss a')})`}
                                                <i className="fa-solid fa-circle ms-2" style={{ color: r.tx ? "green" : 'red' }} />
                                            </div>
                                        </th>

                                    </tr>
                                </thead>
                            </table>

                            <Collapse in={open === index}>
                                <div id={"collapse-rig-" + index} className="mt-2">
                                    <div className="card card-body" style={{ backgroundColor: colors?.colorBg }}>
                                        <table className="table table-sm" style={{ color: colors?.colorFont }}>
                                            <tbody style={{ fontSize: '12px' }}>

                                                <tr>
                                                    <td>Taladro: </td>
                                                    <td>{r.vr_tldro_nom.split('-')[0]}</td>
                                                </tr>
                                                <tr>
                                                    <td>Pozo:</td>
                                                    <td>{r.vr_tldro_nom.split('-').length > 2
                                                        ? r.vr_tldro_nom.split('-')[1] + r.vr_tldro_nom.split('-')[2]
                                                        : r.vr_tldro_nom.split('-')[1]}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Descipción:</td>
                                                    <td>{r.vr_tldro_des === 'TAladro no configurado, creado automáticamente por el sistemaaa' ?
                                                        'Sin Descripción' :
                                                        r.vr_tldro_des}</td>
                                                </tr>
                                                <tr>
                                                    <td>Fecha Inicio Tx:</td>
                                                    <td>{moment(r.dt_adt_fchreg).format('DD MMMM YYYY, h:mm:ss a')}</td>
                                                </tr>
                                                <tr>
                                                    <td>Fecha Fin Tx:</td>
                                                    <td>{moment(r.dt_adt_last_fchreg).format('DD MMMM YYYY, h:mm:ss a')}</td>
                                                </tr>
                                                <tr>
                                                    <td onClick={() => eliminarAsignacionesDuplicadas()}>Opciones</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button className='fa fa-chart-line btn btn-outline-info btn-sm'
                                                                onClick={() => setComponente('livegraphs')} />
                                                            <button className='fa fa-table btn btn-outline-primary btn-sm'
                                                                onClick={() => setComponente('livedata')} />
                                                            {/* <button className='fa fa-braille btn btn-outline-success  btn-sm'
                                                                onClick={() => setComponente('largedata')} /> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Collapse>

                        </div>
                    ))
                    : <div className="table-responsive mt-3">
                        <table className="table table-sm table-hover table-bordered align-middle" id='myTable'
                            style={{ fontSize: '12px' }}>
                            <thead className='text-center' style={{ color: colors?.colorFont || '#000000' }}>
                                <tr>
                                    {thOrd('id', 0)}
                                    {thOrd('Taladro', 1)}
                                    {thOrd('Pozo', 2)}
                                    {thOrd('Descripción', 3)}
                                    {thOrd('Fecha Inicio Tx', 4)}
                                    {thOrd('Fecha Fin Tx', 5)}
                                    {thOrd('tx', 6)}
                                    <th scope="col" onClick={() => eliminarAsignacionesDuplicadas()}>Opciones</th>
                                </tr>
                            </thead>
                            <tbody className='text-center' style={{ color: colors?.colorFont || '#000000' }}>
                                {registrosPaginaActual.map(tldro => (
                                    <tr key={tldro.nb_tldro_idn} onClick={() => setRig(parseInt(tldro.nb_tldro_idn))}>
                                        <td>{tldro.nb_tldro_idn}</td>
                                        <td>{tldro.vr_tldro_nom.split('-')[0]}</td>
                                        <td>{tldro.vr_tldro_nom.split('-').length > 2
                                            ? tldro.vr_tldro_nom.split('-')[1] + tldro.vr_tldro_nom.split('-')[2]
                                            : tldro.vr_tldro_nom.split('-')[1]}
                                        </td>
                                        <td>{tldro.vr_tldro_des === 'TAladro no configurado, creado automáticamente por el sistemaaa' ?
                                            'Sin Descripción' :
                                            tldro.vr_tldro_des}
                                        </td>
                                        <td>{moment(tldro.dt_adt_fchreg).format('DD MMMM YYYY, h:mm:ss a')}</td>
                                        <td>{moment(tldro.dt_adt_last_fchreg).format('DD MMMM YYYY, h:mm:ss a')}</td>
                                        <td><i className="fa-solid fa-circle" style={{ color: tldro.tx ? "green" : 'red' }} /></td>
                                        <td>
                                            <div className="btn-group">
                                                <button className='fa fa-chart-line btn btn-outline-info btn-sm'
                                                    onClick={() => setComponente('livegraphs')}
                                                    title='Graphs' />
                                                <button className='fa fa-table btn btn-outline-primary btn-sm'
                                                    onClick={() => setComponente('livedata')}
                                                    title='Live Data' />
                                                {/* <button className='fa fa-braille btn btn-outline-success btn-sm'
                                                    onClick={() => setComponente('largedata')}
                                                    title='Large Data' /> */}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }


            </>

            <Pagination
                paginaActual={paginaActual}
                totalPaginas={totalPaginas}
                setPaginaActual={setPaginaActual}
                registrosPorPagina={registrosPorPagina}
                setRegistrosPorPagina={setRegistrosPorPagina}
                documentosFiltrados={documentosFiltrados}
                broken={broken}
                collapsed={collapsed}
                mobile={mobile}
            />
        </>
    )
}

export default MyRigs