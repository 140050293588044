import axios from 'axios';
import moment from 'moment';
import { sortBy } from 'lodash';
//import { toast } from 'react-toastify';
import { variablesPath } from '../variables/Variables'

export const deleteBd = async (id, path) => {
    try {
        await fetch(variablesPath.API_URL + `${path}/${id}`, {
            method: 'DELETE',
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                //toast.warning(data);
            });
    } catch (e) {
        throw new Error(e)
    }
};

export const deleteBdNoId = async (path, noMostrar) => {
    try {
        console.log(variablesPath.API_URL + `${path}`);
        await fetch(variablesPath.API_URL + `${path}`, {
            method: 'DELETE',
        })
            .then(response => {
                return response.text();
            })
            /* .then(data => {
                if (!noMostrar) toast.warning(data);
            }); */
    } catch (e) {
        //throw new Error(e)
    }
};

export const getTableBd = async (path) => {
    try {
        const res = await axios.get(variablesPath.API_URL + path)
        return (res.data);
    } catch (e) {
        console.error(e);
        throw new Error(e)
    }
};

export const getTableByIdBd = async (id, path) => {
    try {
        const res = await axios.get(variablesPath.API_URL + path + '/' + id)
        return (res.data);
    } catch (e) {
        return ([])
        return { error: true, message: e.message };
    }
};

export const updateTableBd = async (path, dataSend) => {
    try {
        const response = await axios.put(variablesPath.API_URL + path, dataSend, {
            headers: { 'Content-Type': 'application/json', },
        });
        return response.data;
    } catch (e) {
        throw new Error(e)
    }
};

export const insertTableBd = async (path, dataSend) => {
    try {
        const response = await axios.post(variablesPath.API_URL + path, dataSend, {
            headers: { 'Content-Type': 'application/json', },
        });
        return response.data;
    } catch (e) {
        throw new Error(e)
    }
};

export const validarMobil = (setMobile, setHorizontal) => {
    const handleResize = () => {
        if (window.innerWidth < 768 || window.innerHeight < 500) {
            setMobile(true)
            setHorizontal(window.innerWidth > window.innerHeight);
        }
        else {
            setMobile(false)
            setHorizontal(false);
        }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize) };
}

//---------------------------------------------------------------------

export const getVariables = async (setVariables) => {
    const vars = await getTableBd('getvars')
    setVariables(vars);
}

//--------------------------------------------------------------------

export const ordenar = (columna) => {
    var tables, rows, sorting, c, a, b, tblsort;
    tables = document.getElementById("myTable");
    sorting = true;
    while (sorting) {
        sorting = false;
        rows = tables.rows;
        for (c = 1; c < (rows.length - 1); c++) {
            tblsort = false;
            a = rows[c].getElementsByTagName("TD")[columna];
            b = rows[c + 1].getElementsByTagName("TD")[columna];
            if (a.innerHTML.toLowerCase() > b.innerHTML.toLowerCase()) {
                tblsort = true;
                break;
            }
        }
        if (tblsort) {
            rows[c].parentNode.insertBefore(rows[c + 1], rows[c]);
            sorting = true;
        }
    }
};

export const ordenarArreglo = (columna, arreglo, setArreglo) => {
    const ordenado = sortBy(arreglo, [item => item[columna].toLowerCase()])
    setArreglo(ordenado)
}

export const compararFechas = (f1, f2, tipo) => {
    const f1Date = new Date(f1);
    const f2Date = new Date(f2);
    if (tipo === 'inicial') {
        if (f1Date < f2Date) return f1
        return f2
    }
    if (tipo === 'final') {
        if (f1Date < f2Date) return f2
        return f1
    }
}


export const txVivo = (fecha) => {
    const lastTransmission = moment(fecha);
    const now = moment();
    const differenceInMinutes = now.diff(lastTransmission, 'minutes');
    let tx = false;
    if (differenceInMinutes <= 5) {
        tx = true
    } else {
        tx = false
    }
    return tx
}